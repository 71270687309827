import React from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import arrow from '../../images/arrow-spotview.svg';
import { Link } from 'gatsby-plugin-react-i18next';

const Wrapper = styled.div`
  .servizi-wrapper {
    position: relative;
  }

  .servizi-list {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    list-style-type: none;

    li {
      cursor: pointer;
      height: 110px;
      max-height: 110px;
      transition: max-height 0.5s ease-in-out;
      overflow: hidden;

      @media (min-width: 1025px) {
        &:hover {
          background: ${theme.colorBlack};
          color: ${theme.colorWhite};
          /* margin: 0 -50px;
          padding: 0 50px; */
        }
      }

      .accordion-title {
        font-family: ${theme.fontSyne};
        height: 110px;
        font-weight: 700;
        font-size: 49px;
        text-transform: uppercase;
        line-height: 80px;
        letter-spacing: -2px;
        display: flex;
        align-items: center;

        svg {
          display: none;
        }

        .servizi-arrow {
          display: none;
        }
      }

      .accordion-content {
        background: ${theme.colorBlack};
        color: ${theme.colorWhite};
        display: block;
        font-family: ${theme.fontNeue};
        font-weight: 500;
        font-size: 27px;

        span {
          width: calc(100% / 12 * 8);
          padding: 0 0 25px;
          display: inline-block;
        }
      }

      &.is-open {
        background: ${theme.colorBlack};
        color: ${theme.colorWhite};
        /* margin: 0 -50px;
        padding: 0 50px; */
        height: auto;
        max-height: 300px;

        .accordion-content {
          display: block;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .servizi-list {
      font-size: 30px;

      li {
        &:hover {
          margin: 0 -20px;
          padding: 0 20px;
        }

        .accordion-title {
          font-size: 30px;
          line-height: 49px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            display: block;
          }

          .servizi-arrow {
            display: block;
            min-width: 25px;
          }
        }

        .accordion-content {
          font-size: 18px;
          line-height: 26px;

          span {
            width: 100%;
          }
        }

        &.is-open {
          margin: 0 -20px;
          padding: 0 20px;
        }
      }
    }
  }
`;

export default function SectionServizi({ data }) {
  return (
    <Wrapper>
      <div className="servizi-wrapper">
        <ul className="servizi-list">
          {data.map((s, i) => (
            <li key={i} className="hover-card">
              <div className="container mx-auto">
                <Link to={'/servizi/' + s.slug} alt={s.titolo}>
                  <div className="accordion-title">
                    {s.titolo}
                    <span className="servizi-arrow">
                      <img src={arrow} alt="" />
                    </span>
                  </div>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
}
