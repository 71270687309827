import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/commons/Layout';
import styled from 'styled-components';
import theme from '../constants/theme';
import ResponsiveText from '../components/typography/ResponsiveText';
import SectionServizi from '../components/sections/SectionServizi';

const CasiStudioWrapper = styled.div`
  margin-bottom: 50px;

  .page-wrapper {
    min-height: 100vh;
  }

  .content-wrapper {
    position: relative;
    margin-top: 90px;
  }

  .filters {
    margin-top: 50px;
    width: 50%;
    position: relative;
    z-index: 5;

    .filter-trigger {
      display: none;
      font-family: ${theme.fontSyne};
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: -2px;
      line-height: 34px;
      padding: 20px 20px;
      color: ${theme.colorWhite};
      background-color: ${theme.colorBlack};
      margin: 0 -20px;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      li {
        font-family: ${theme.fontSyne};
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: -2px;
        line-height: 34px;
        padding: 20px 0;
        color: ${theme.colorGray};
        cursor: pointer;
        z-index: 2;

        &.active {
          color: ${theme.colorBlack};
        }

        &:hover {
          color: ${theme.colorBlack};
        }
      }
    }
  }

  .casi-grid {
    display: flex;
    flex-wrap: wrap;
    columns: 2;
    margin-top: -150px;
    row-gap: 100px;

    .casi-block {
      width: 50%;
      display: flex;
      justify-content: center;

      & > div {
        flex: 0 0 auto;
        margin: 0 auto;
      }

      &:nth-child(2n + 1) {
        margin-top: -40vh;
      }
    }
  }

  @media (max-width: 1024px) {
    .content-wrapper {
      margin-top: 40px;
    }

    .filters {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 40px;

      .filter-trigger {
        display: flex;
      }

      ul {
        display: none;

        &.active {
          display: block;
        }

        li {
          color: ${theme.colorBlack};
          margin: 0 -20px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: ${theme.colorWhite};

          &.active {
            background-color: ${theme.colorYellow};
          }
        }
      }
    }

    .casi-grid {
      display: grid;
      margin-top: 0;
      row-gap: 30px;
      column-gap: 30px;
      grid-template-columns: repeat(2, 1fr);

      .casi-block {
        margin: 0;
        width: 100%;

        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2n + 1) {
          margin-top: 0;
        }

        .caso-card {
          & > div {
            width: 100%;
            height: auto;
            max-height: unset;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .casi-grid {
      grid-template-columns: unset;
    }
  }

  // SMALL SCREENS
  @media (max-width: 374px) {
    .filters {
      .filter-trigger,
      ul > li {
        font-size: 22px;
      }
    }

    .casi-block[data-title='LloydsFarmacia'] {
      h3 {
        font-size: 20px;
      }
    }
  }
`;

const ServiziPage = ({ data }) => {
  const {
    backend: { servizis, seoRules },
  } = data;

  return (
    <Layout seo={seoRules && seoRules[0] && seoRules[0].seo}>
      <CasiStudioWrapper data-scroll-section>
        <div className="page-wrapper">
          <div className="container mx-auto">
            <ResponsiveText type="extreme">
              <h2>Servizi</h2>
            </ResponsiveText>
          </div>
          <div className="content-wrapper">
            <SectionServizi data={servizis} />
          </div>
        </div>
      </CasiStudioWrapper>
    </Layout>
  );
};

export default ServiziPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      servizis {
        slug
        titolo
        ordine
      }
      seoRules(where: { slug_eq: "/servizi/" }) {
        slug
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`;
